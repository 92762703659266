<div class="user-profile" [class]="show ? 'open' : ''">
  <a
    class="btn btn-xs btn-icon btn-light align-self-end"
    id="kt_user_profile_close"
    (click)="actionUserProfile(false)"
  >
    <i class="ki ki-close icon-xs text-muted"></i>
  </a>
  <div class="item-wrapper">
    <img alt="User Photo" src="assets/images/default.jpg" class="h-72" />
    <span class="user-profile-name"
      >{{ user ? user.name : "" }} {{ user ? user.surname : "" }}</span
    >
    <span class="user-profile-rol">{{ user ? user.roles[0] : "" }}</span>
  </div>
  <span class="user-profile-email">{{ user ? user.username : "" }}</span>
  <hr />
  <button type="button" (click)="logout()" class="btn btn-success btn-logout">
    Logout
  </button>
</div>

<!--begin::Overlay-->
<div
  class="offcanvas-overlay"
  *ngIf="show"
  (click)="actionUserProfile(false)"
></div>
<!--end::Overlay-->
