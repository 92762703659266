import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() { }

  // Return string d : hh : mm : ss
  format_1(timestamp:number) {

    let s = Math.floor(timestamp / 1000);
    let d = 86400;
    let h = 3600;
    let m = 60;
    let days = Math.floor(s/d)
    let hours = Math.floor((s % d)/h);
    let minutes = Math.floor((s % h)/m);
    let seconds = Math.floor((s % m));

    let timeString = days + 'd' + ' : '
                          + (hours < 10 ? '0' + hours : hours) + 'h' + ' : '
                          + (minutes < 10 ? '0' + minutes : minutes) + 'm' + ' : '
                          + (seconds < 10 ? '0' + seconds : seconds) + 's';

    return timeString;

  }

  // Return string hh:mm:ss
  format_2(timestamp:number) {
    let s = Math.floor(timestamp / 1000);
    let h = 3600;
    let m = 60;
    let hours = Math.floor(s/h);
    let minutes = Math.floor((s % h)/m);
    let seconds = Math.floor((s % m));
    let timeString = '';

    timeString = (hours < 10 ? '0' + hours : hours) + ':'
      + (minutes < 10 ? '0' + minutes : minutes)
      + ':' + (seconds < 10 ? '0' + seconds : seconds);

    return timeString;
  }

  // Return string yyyy-mm-dd
  formatDateString(date: Date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  // Return string dd-mm-yyyy hh:mm:ss
  formatTimestampToDate(timestamp:number) {
    let ts_ms = timestamp;

    // initialize new Date object
    let date_ob = new Date(ts_ms);

    // year as 4 digits (YYYY)
    let year = date_ob.getFullYear();

    // month as 2 digits (MM)
    let month = ('0' + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    let date = ('0' + date_ob.getDate()).slice(-2);

    // hours as 2 digits (hh)
    let hours = ('0' + date_ob.getHours()).slice(-2);

    // minutes as 2 digits (mm)
    let minutes = ('0' + date_ob.getMinutes()).slice(-2);

    // seconds as 2 digits (ss)
    let seconds = ('0' + date_ob.getSeconds()).slice(-2);


    return date + '-' + month + '-' + year + ' ' + hours + ':' + minutes + ':' + seconds
  }

}
