<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Connection Failure</h4>
  <button type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body d-flex justify-content-between">
  <div class="modal-body-icon">
    <i class="flaticon2-information icon-6x"></i>
  </div>
  <div *ngIf="user.selectedCore && user.selectedSite" class="modal-body-text">
    <p>
      Problems fetching data from <b>{{ user.coreName }}</b>
    </p>
    <p>Wait for reconnection or select another core</p>
    <p>Retrying connection in: {{ time }}</p>
  </div>
  <div *ngIf="!user.selectedCore && !user.selectedSite" class="modal-body-text">
    <p>Problems fetching sites and cores</p>
    <p>Please contact an administrator for further support</p>
    <p>Retrying connection in: {{ time }}</p>
  </div>
</div>
