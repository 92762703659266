<!--begin::Aside-->
<div
  class="aside aside-left d-flex flex-column"
  id="kt_aside"
  [class]="showAsideMobile ? 'aside-on' : ''"
>
  <!--begin::Nav Wrapper-->
  <div
    class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-9 pb-10"
  >
    <!--begin::Nav-->
    <ul class="nav flex-column">
      <!--begin::Item-->
      <li
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Dashboard"
        (click)="closeDropdowns()"
      >
        <a
          [ngClass]="{ active: tabActive == 'dashboard' }"
          routerLink="/admin/dashboard"
          class="nav-link btn btn-icon btn-lg btn-borderless"
          data-toggle="tab"
          data-target="#kt_aside_tab_2"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-dashboard-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g id="dashboard-icon" clip-path="url(#clip-dashboard-icon)">
                <g id="Group_4042" data-name="Group 4042">
                  <path
                    id="Union_5"
                    data-name="Union 5"
                    d="M11.5,18A1.5,1.5,0,0,1,10,16.5v-5A1.5,1.5,0,0,1,11.5,10h5A1.5,1.5,0,0,1,18,11.5v5A1.5,1.5,0,0,1,16.5,18Zm-10,0A1.5,1.5,0,0,1,0,16.5v-5A1.5,1.5,0,0,1,1.5,10h5A1.5,1.5,0,0,1,8,11.5v5A1.5,1.5,0,0,1,6.5,18Zm10-10A1.5,1.5,0,0,1,10,6.5v-5A1.5,1.5,0,0,1,11.5,0h5A1.5,1.5,0,0,1,18,1.5v5A1.5,1.5,0,0,1,16.5,8ZM1.5,8A1.5,1.5,0,0,1,0,6.5v-5A1.5,1.5,0,0,1,1.5,0h5A1.5,1.5,0,0,1,8,1.5v5A1.5,1.5,0,0,1,6.5,8Z"
                    transform="translate(3 3)"
                    fill="#1e1f21"
                  />
                  <rect
                    id="Rectangle_900"
                    data-name="Rectangle 900"
                    width="23.343"
                    height="23.343"
                    transform="translate(0.329 0.329)"
                    fill="#1e1f21"
                    opacity="0"
                  />
                  <path
                    id="Path_312"
                    data-name="Path 312"
                    d="M0,0H24V24H0Z"
                    fill="#1e1f21"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Devices"
        (click)="closeDropdowns()"
      >
        <a
          [ngClass]="{ active: tabActive == 'devices' }"
          routerLink="/admin/devices"
          class="nav-link btn btn-icon btn-lg btn-borderless"
          data-toggle="tab"
          data-target="#kt_aside_tab_2"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-device-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g
                id="device-icon"
                clip-path="url(#clip-device-icon)"
                fill="#FFFFFF"
              >
                <g id="Group_4041" data-name="Group 4041">
                  <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M14,18v2l2,1v1H8l0-1,2-1V18H2.992A1,1,0,0,1,2,16.993V4.007A1.007,1.007,0,0,1,2.992,3H21.008A1,1,0,0,1,22,4.007V16.993A1.007,1.007,0,0,1,21.008,18ZM4,5v9H20V5Z"
                    transform="translate(0 0)"
                    fill="#1e1f21"
                  />
                  <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M0,0H24V24H0Z"
                    fill="#1e1f21"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Casts"
        (click)="closeDropdowns()"
      >
        <a
          [ngClass]="{ active: tabActive == 'casts' }"
          routerLink="/admin/casts"
          class="nav-link btn btn-icon btn-lg btn-borderless"
          data-toggle="tab"
          data-target="#kt_aside_tab_3"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-cast-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g id="cast-icon" clip-path="url(#clip-cast-icon)">
                <g id="Group_4038" data-name="Group 4038">
                  <path
                    id="Path_489"
                    data-name="Path 489"
                    d="M3,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H15a13.1,13.1,0,0,0-.153-2H20V5H4V8.153A13.1,13.1,0,0,0,2,8V4A1,1,0,0,1,3,3ZM13,21H11a9,9,0,0,0-9-9V10A11,11,0,0,1,13,21ZM9,21H7a5,5,0,0,0-5-5V14a7,7,0,0,1,7,7ZM5,21H2V18A3,3,0,0,1,5,21Z"
                    fill="#1e1f21"
                  />
                  <path
                    id="Path_488"
                    data-name="Path 488"
                    d="M0,0H24V24H0Z"
                    fill="#1e1f21"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Pairs"
        (click)="closeDropdowns()"
      >
        <a
          [ngClass]="{ active: tabActive == 'pairs' }"
          routerLink="/admin/pairs"
          class="nav-link btn btn-icon btn-lg btn-borderless"
          data-toggle="tab"
          data-target="#kt_aside_tab_4"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              class="pair"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-pair-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g id="pair-icon" clip-path="url(#clip-pair-icon)">
                <g id="Group_3997" data-name="Group 3997">
                  <path
                    id="Path_482"
                    data-name="Path 482"
                    d="M0,0H24V24H0Z"
                    fill="none"
                  />
                  <path
                    id="iconmonstr-connection-7"
                    d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Zm1.738,11.667h-7.5v1.667h7.5v2.5L15.9,12.5,11.737,9.167v2.5Zm-3.333-5v-2.5L4.237,7.5,8.4,10.833v-2.5h7.5V6.667H8.4Z"
                    transform="translate(2 2)"
                    fill-rule="evenodd"
                  />
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        id="item-rooms"
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        (click)="actionDropdownRooms()"
        title="Rooms"
      >
        <a
          [class]="
            tabActive == 'rooms' || tabActive == 'room-groups'
              ? 'multi-option-active active'
              : ''
          "
          class="nav-link btn btn-icon btn-lg btn-borderless multi-option"
          data-toggle="tab"
          data-target="#kt_aside_tab_5"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-room-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g id="room-icon" clip-path="url(#clip-room-icon)">
                <g
                  id="Group_4040"
                  data-name="Group 4040"
                  transform="translate(0 0)"
                >
                  <path
                    id="Path_485"
                    data-name="Path 485"
                    d="M22,11v9H20V17H4v3H2V4H4V14h8V7h6A4,4,0,0,1,22,11ZM8,13a3,3,0,1,1,3-3A3,3,0,0,1,8,13Z"
                    transform="translate(0 0)"
                    fill="#1e1f21"
                  />
                  <path
                    id="Path_484"
                    data-name="Path 484"
                    d="M0,0H24V24H0Z"
                    fill="#1e1f21"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Ads"
        (click)="closeDropdowns()"
      >
        <a
          [ngClass]="{ active: tabActive == 'ads' }"
          routerLink="/admin/ads"
          class="nav-link btn btn-icon btn-lg btn-borderless"
          data-toggle="tab"
          data-target="#kt_aside_tab_5"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-ad-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g id="ad-icon" clip-path="url(#clip-ad-icon)">
                <g id="Group_4039" data-name="Group 4039">
                  <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M21,3a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3ZM9.4,8h-2L4.2,16H6.353l.4-1h3.29l.4,1H12.6ZM19,8H17v2H16a3,3,0,0,0-.176,6L16,16h3Zm-2,4v2H16l-.117-.007a1,1,0,0,1,0-1.986L16,12ZM8.4,10.885,9.244,13H7.552Z"
                    transform="translate(0)"
                    fill="#1e1f21"
                  />
                  <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M0,0H24V24H0Z"
                    fill="#1e1f21"
                    opacity="0"
                  />
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
      <!--begin::Item-->
      <!-- <li
        id="item-customization"
        class="nav-item mb-5"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Custom"
        (click)="notDropdown()"
      >
        <a
          routerLinkActive="active"
          routerLink="/admin/backdrop"
          class="nav-link btn btn-icon btn-lg btn-borderless multi-option"
          data-toggle="tab"
          data-target="#kt_aside_tab_5"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              class="icon icon-eyedropper"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <symbol id="icon-eyedropper" viewBox="0 0 32 32">
                <path d="M30.828 1.172c-1.562-1.562-4.095-1.562-5.657 0l-5.379 5.379-3.793-3.793-4.243 4.243 3.326 3.326-14.754 14.754c-0.252 0.252-0.358 0.592-0.322 0.921h-0.008v5c0 0.552 0.448 1 1 1h5c0 0 0.083 0 0.125 0 0.288 0 0.576-0.11 0.795-0.329l14.754-14.754 3.326 3.326 4.243-4.243-3.793-3.793 5.379-5.379c1.562-1.562 1.562-4.095 0-5.657zM5.409 30h-3.409v-3.409l14.674-14.674 3.409 3.409-14.674 14.674z"></path>
              </symbol>
              <use xlink:href="#icon-eyedropper"></use>
            </svg>
          </span>
        </a>
      </li> -->
      <!--end::Item-->
      <!--begin::Item-->
      <li
        class="nav-item mb-2"
        data-toggle="tooltip"
        data-placement="right"
        data-container="body"
        data-boundary="window"
        title="Advanced"
        (click)="notDropdown()"
      >
        <a
          routerLinkActive="active"
          routerLink="/admin/advanced"
          class="nav-link btn btn-icon btn-lg btn-borderless"
          data-toggle="tab"
          data-target="#kt_aside_tab_6"
          role="tab"
        >
          <span class="svg-icon svg-icon-xxl svg-aside">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <defs>
                <clipPath id="clip-tools-icon">
                  <rect width="24" height="24" />
                </clipPath>
              </defs>
              <g
                id="tools-icon"
                clip-path="url(#clip-tools-icon)"
                fill="#FFFFFF"
              >
                <g id="build_circle-black-24dp">
                  <g id="Group_622" data-name="Group 622">
                    <rect
                      id="Rectangle_157"
                      data-name="Rectangle 157"
                      width="24"
                      height="24"
                      fill="none"
                      opacity="0"
                    />
                  </g>
                  <g id="Group_624" data-name="Group 624">
                    <g id="Group_623" data-name="Group 623">
                      <path
                        id="Path_136"
                        data-name="Path 136"
                        d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm4.9,13.49-1.4,1.4a.5.5,0,0,1-.71,0l-3.41-3.41A3.459,3.459,0,0,1,7.17,8.57l2.35,2.35,1.41-1.41L8.58,7.17a3.459,3.459,0,0,1,4.91,4.21l3.41,3.41A.5.5,0,0,1,16.9,15.49Z"
                        fill-rule="evenodd"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </a>
      </li>
      <!--end::Item-->
    </ul>
    <!--end::Nav-->
  </div>
  <!--end::Nav Wrapper-->
</div>
<!--end::Aside-->

<div class="btn-group dropright aside-dropdown">
  <div
    id="dropdownRooms"
    class="dropdown-menu"
    [class]="dropdownRooms ? 'show' : ''"
  >
    <a
      [ngClass]="{ active: tabActive == 'rooms' }"
      routerLink="/admin/rooms"
      class="dropdown-item"
      (click)="closeDropdowns()"
      >Rooms</a
    >
    <a
      [ngClass]="{ active: tabActive == 'room-groups' }"
      routerLink="/admin/room-groups"
      class="dropdown-item"
      (click)="closeDropdowns()"
      >Room Groups</a
    >
  </div>
</div>

<div class="btn-group dropright aside-dropdown">
  <div
    id="dropdownCustomization"
    class="dropdown-menu"
    [class]="dropdownCustomization ? 'show' : ''"
  >
    <a
      routerLinkActive="active"
      routerLink="/admin/backdrop"
      class="dropdown-item"
      (click)="closeDropdowns()"
      >Backdrop</a
    >
    <a
      routerLinkActive="active"
      routerLink="/admin/register"
      class="dropdown-item"
      (click)="closeDropdowns()"
      >Register</a
    >
  </div>
</div>