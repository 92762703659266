import {Injectable} from '@angular/core';
import {catchError, take} from 'rxjs/internal/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {SessionService} from './session.service';
import {AssetsLoaderService} from './assets-loader.service';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class GraphqlService {

  protected url: string;
  protected headers: HttpHeaders;

  env = environment;

  constructor(protected httpClient: HttpClient,
              protected router: Router,
              protected sessionService: SessionService,
              protected assetsLoaderService: AssetsLoaderService) {
  }

  post(param) {
    // // Get Environment from config.json
    // this.assetsLoaderService.loadConfigurations()
    //   .subscribe(res => {
    //     // Set value config to env
    //     this.url = res.castApi;
    //
    //   });
    this.headers = new HttpHeaders({'content-type': 'application/json'});

    const payload = JSON.stringify({
      query: param,
      variable: {}
    });

    return this.httpClient
      .post(this.env.master + 'graphql', payload, {headers: this.headers})
      .pipe(
        take(1),
        catchError(err => this.handleErr(err))
      );
  }


  protected handleErr(err): Observable<any> {
    console.error('Graphql Service Error: ', err);
    throw err;
  }

}
