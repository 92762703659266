import { Component, OnInit } from '@angular/core';
import { GraphqlService } from './shared/services/graphql.service';
import { SessionService } from './shared/services/session.service';
import { KeycloakService } from 'keycloak-angular';
import { User } from './shared/interfaces/user';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PublicControllerService } from '@cast/core-typescript-angular';
import { ModalDataService } from './shared/services/modal-data.service';
import { ModalComponent } from './features/modal/modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'cast-admin';

  user: User = {
    email: '',
    name: '',
    surname: '',
    roles: [],
    sites: [],
    cores: [],
    coreName: '',
    sub: '',
    username: '',
    selectedSite: null,
    selectedCore: null,
  };

  env = environment;
  selectedSite: string;
  selectedCore: string;
  basePath: string;
  showAsideMobile = false;
  protected headers: HttpHeaders;
  private modalRef: NgbModalRef;

  constructor(
    private graphqlService: GraphqlService,
    private sessionService: SessionService,
    private keyCloakAngular: KeycloakService,
    private modalService: NgbModal,
    private publicService: PublicControllerService,
    private modalDataService: ModalDataService
  ) {}

  ngOnInit(): void {
    this.sessionService.getUser().subscribe((user) => {
      if (
        this.selectedSite !== user.selectedSite ||
        this.selectedCore !== user.selectedCore
      ) {
        // Rewrite path api
        this.publicService.configuration.basePath =
          this.env.proxy + user.selectedCore;
        this.testConnection();
      }
    });

    setInterval(() => {
      this.testConnection();
    }, 10000);

    // User Keycloak
    this.keyCloakAngular
      .isLoggedIn()
      .then((loggedIn) => {
        if (loggedIn) {
          this.keyCloakAngular
            .loadUserProfile()
            .then((profile) => {
              this.user.name = profile.firstName;
              this.user.surname = profile.lastName;
              this.user.roles = this.keyCloakAngular.getUserRoles();
              this.user.username = profile.username;

              const param = `{
                siteList{
                  id,
                  name
                }
              }`;

              // Sites User
              this.graphqlService.post(param).subscribe((sites) => {
                if (sites.data.siteList.length > 0) {
                  this.user.sites = sites.data.siteList;
                  this.user.selectedSite =
                    sites.data.siteList.length > 0
                      ? sites.data.siteList[0].id
                      : null;

                  const PARAM_CORE = `{
                    castCoreList(siteId:"${this.user.selectedSite}"){
                      id,
                      name
                    }
                  }`;

                  // Cores User
                  this.graphqlService.post(PARAM_CORE).subscribe((cores) => {
                    this.user.cores = cores.data.castCoreList;
                    this.user.selectedCore =
                      cores.data.castCoreList.length > 0
                        ? cores.data.castCoreList[0].id
                        : null;
                    this.user.coreName =
                      cores.data.castCoreList.length > 0
                        ? cores.data.castCoreList[0].name
                        : '';
                    this.sessionService.setUser(this.user);
                  });
                } else {
                  this.user.sites = [{ id: null, name: 'No Site' }];
                  this.user.selectedSite = this.user.sites[0].id;
                  this.user.cores = [{ id: null, name: 'No Core' }];
                  this.user.selectedCore = this.user.cores[0].id;
                  this.sessionService.setUser(this.user);
                }
              });
            })
            .catch((reason) => {
              console.log(reason);
            });
        }
      })
      .catch((reason) => console.log(reason));
  }

  testConnection() {
    this.publicService.health().subscribe(
      () => {
        this.modalRef.dismiss();
      },
      (error) => {
        if (!this.modalService.hasOpenModals()) {
          this.openModal();
        }
      }
    );
  }

  openModal() {
    const data = {
      test: 'some data',
    };

    this.modalDataService.setData(data);

    this.modalRef = this.modalService.open(
      ModalComponent,
      ModalComponent.modalConfig
    );

    this.modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {}
    );
  }

  parentHandleClick($event) {
    this.showAsideMobile = $event;
  }
}
