import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private user: BehaviorSubject<User> = new BehaviorSubject<User>({
    email: '',
    name: '',
    surname: '',
    roles: [],
    sites: [],
    cores: [],
    coreName: '',
    sub: '',
    username: '',
    selectedSite: null,
    selectedCore: null,
  });

  constructor(private modalService: NgbModal) {}

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.modalService.dismissAll();
    this.user.next({ ...user });
  }

  setSite(siteId, cores, coreId, coreName) {
    const user: User = this.user.getValue();
    user.selectedSite = siteId;
    user.cores = cores;
    user.selectedCore = coreId;
    user.coreName = coreName;
    this.setUser(user);
  }

  setCore(id, name) {
    const user: User = this.user.getValue();
    user.selectedCore = id;
    user.coreName = name;

    this.setUser(user);
  }
}
