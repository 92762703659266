import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent implements OnInit {
  @Input() public showAsideMobile: boolean = false;

  dropdownRooms = false;
  dropdownCustomization = false;

  offsetTop = 0;
  tabActive = '';

  @HostListener('document:click', ['$event.target']) onMouseEnter(
    targetElement
  ) {
    const clickedInside = this.eRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closeDropdowns();
    }
  }

  constructor(private eRef: ElementRef, public router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) this.selectTabActive(e.url);
    });
  }

  closeDropdowns() {
    this.dropdownRooms = false;
    this.dropdownCustomization = false;
  }

  actionDropdownRooms() {
    this.closeDropdowns();
    this.offsetTop = (<HTMLElement>(
      document.getElementById('item-rooms')
    )).offsetTop;
    (<HTMLElement>document.getElementById('dropdownRooms')).style.top =
      this.offsetTop.toString() + 'px';

    this.dropdownRooms = true;
  }

  notDropdown() {
    this.dropdownRooms = false;
  }

  private selectTabActive(url: string) {
    const secondSegment = url.split('/')[2];
    this.tabActive = secondSegment;
  }
  actionDropdownCustomization(){
    this.closeDropdowns();
    this.offsetTop = (<HTMLElement> document.getElementById('item-customization')).offsetTop;
    (<HTMLElement> document.getElementById('dropdownCustomization')).style.top = this.offsetTop.toString() + 'px';

    this.dropdownCustomization = true;

  }


}
