import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input() headers: any[] = [];
  @Input() data: any[] = [];
  @Input() hasActionButtons: boolean = false;
  @Input() hasActionSelectRow: boolean = false;
  @Input() selectedRow: number = null;
  @Input() loading: boolean = true;
  @Output() pageableSort = new EventEmitter();
  @Output() onActionButton = new EventEmitter();
  @Output() onActionSelectRow = new EventEmitter();
  @Output() onActionRoomGroup = new EventEmitter();
  @Output() onActionPreview = new EventEmitter();

  order = 'desc';
  orderBy = 'id';

  constructor() {}

  ngOnInit(): void {}

  sort(key, sortable) {
    if (sortable) {
      this.orderBy = key;

      if (this.order === 'asc') {
        this.order = 'desc';
      } else {
        this.order = 'asc';
      }

      this.pageableSort.emit([this.orderBy + ',' + this.order]);
    }
  }

  actionButton(row, type) {
    this.onActionButton.emit({
      row: row,
      type: type,
    });
  }

  actionSelectRow(i) {
    if (this.hasActionSelectRow) {
      this.onActionSelectRow.emit(i);
    }
  }

  actionRoomGroup(id) {
    this.onActionRoomGroup.emit(id);
  }

  actionPreview(i) {
    this.onActionPreview.emit(i);
  }

  isArray(item) {
    return Array.isArray(item);
  }
}
