import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-eroom-products',
  templateUrl: './eroom-products.component.html',
  styleUrls: ['./eroom-products.component.scss']
})
export class EroomProductsComponent implements OnInit {

  @Input() active = '';

  isShowModalProducts: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showProducts () {
    this.isShowModalProducts = !this.isShowModalProducts
  }

  navigateTo (url: string) {
    if(url !== ''){
      window.open(url, '_blank');
    }else{
      window.open("https://www.eroomsuite.com", '_blank');
    }
   
  }

}
