<!-- begin::Main-->
<!--begin::Header Mobile-->
<app-header-mobile
  (accordionAction)="parentHandleClick($event)"
  [showAsideMobile]="showAsideMobile"
></app-header-mobile>
<!--end::Header Mobile-->
<div class="d-flex flex-column flex-root">
  <!--begin::Page-->
  <div class="d-flex flex-row flex-column-fluid page">
    <!--begin::Aside-->
    <app-aside [showAsideMobile]="showAsideMobile"></app-aside>
    <!--end::Aside-->
    <!--begin::Wrapper-->
    <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
      <!--begin::Header-->
      <app-header></app-header>
      <!--end::Header-->

      <!--begin::Content-->
      <div
        class="content d-flex flex-column flex-column-fluid"
        id="kt_content"
        style="position: relative"
      >
        <router-outlet></router-outlet>
      </div>
      <!--end::Content-->

      <!--begin::Footer-->
      <!--end::Footer-->
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Page-->
</div>
<!--end::Main -->
