import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SessionService } from 'src/app/shared/services/session.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  @Input() user: any;
  @Input() show: boolean;
  @Output() showEvent = new EventEmitter<boolean>();

  constructor(
    private sessionService: SessionService,
    private keyCloakAngular: KeycloakService
  ) {}

  ngOnInit(): void {}

  actionUserProfile(value: boolean) {
    this.show = value;
    this.showEvent.emit(value);
  }

  logout() {
    // this.sessionService.clearToken();
    this.keyCloakAngular.logout();
  }
}
