import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalDataService {

  private data = new BehaviorSubject<any>({});

  data$ = this.data.asObservable();

  constructor() { }

  setData(data){
    this.data.next(data);
  }
}
