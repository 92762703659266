import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'src/app/shared/services/session.service';
import { interval, Subscription } from 'rxjs';
import { DateFormatService } from 'src/app/shared/services/date-format.service';
import { User } from 'src/app/shared/interfaces/user';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    public sessionService: SessionService,
    public dateFormatService: DateFormatService
  ) {}

  static modalConfig: NgbModalOptions = {
    centered: true,
    windowClass: 'connection-modal',
    backdropClass: 'connection-modal-backdrop',
    backdrop: 'static',
  };

  user: User;

  time: number;
  private subscription: Subscription;

  ngOnInit(): void {
    const timerInit = 10;
    this.sessionService.getUser().subscribe((user) => {
      this.user = user;

      // replace with an user's subscription attribute if neccesary
      this.time = timerInit; // 10 sec
    });

    this.subscription = interval(1000).subscribe(() => {
      if (this.time === 0) {
        setTimeout(() => {
          this.time = timerInit;
        }, 1000);
      } else {
        this.time -= 1;
      }
    });
  }
}
