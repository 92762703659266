import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/guards/auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'ads',
        loadChildren: () =>
          import('./features/ads/ads.module').then((m) => m.AdsModule),
      },
      {
        path: 'casts',
        loadChildren: () =>
          import('./features/cast/cast.module').then((m) => m.CastModule),
      },
      {
        path: 'devices',
        loadChildren: () =>
          import('./features/devices/devices.module').then(
            (m) => m.DevicesModule
          ),
      },
      {
        path: 'pairs',
        loadChildren: () =>
          import('./features/pairs/pairs.module').then((m) => m.PairsModule),
      },
      {
        path: 'rooms',
        loadChildren: () =>
          import('./features/rooms/rooms.module').then((m) => m.RoomsModule),
      },
      {
        path: 'room-groups',
        loadChildren: () =>
          import('./features/rooms-groups/rooms-groups.module').then(
            (m) => m.RoomsGroupsModule
          ),
      },
      {
        path: 'room-groups/:id',
        loadChildren: () =>
          import('./features/rooms-groups/rooms-groups.module').then(
            (m) => m.RoomsGroupsModule
          ),
      },
      {
        path: 'advanced',
        loadChildren: () =>
          import('./features/advance/advance.module').then(
            (m) => m.SettingAdvanceModule
          ),
      },
      {
        path: 'backdrop',
        loadChildren: () =>
          import('./features/backdrop/backdrop.module').then(
            (m) => m.BackdropModule
          ),
      },
    ],
  },
  { path: '', redirectTo: 'admin', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
