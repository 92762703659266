import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

interface Configuration {
  production: boolean;
  castApi: string;
}


@Injectable({
  providedIn: 'root'
})
export class AssetsLoaderService {

  private readonly CONFIG_URL = 'assets/config/config.json';

  constructor(private http: HttpClient) { }


  public loadConfigurations(): any {
    return this.http.get<Configuration>(this.CONFIG_URL);
  }

}
