import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsideComponent } from './components/aside/aside.component';
import { HeaderComponent } from './components/header/header.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { RouterModule } from '@angular/router';
import { HeaderMobileComponent } from './components/header-mobile/header-mobile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TableComponent } from './components/table/table.component';
import { EroomProductsComponent } from './components/eroom-products/eroom-products.component';

@NgModule({
  declarations: [
    AsideComponent,
    HeaderComponent,
    UserProfileComponent,
    HeaderMobileComponent,
    TableComponent,
    EroomProductsComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, NgSelectModule],
  exports: [HeaderComponent, AsideComponent, HeaderMobileComponent, TableComponent],
})
export class SharedModule {}
