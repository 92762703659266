<div class="table-responsive">
  <table class="table table-vertical-center" id="kt_advance_table_widget_1">
    <thead>
      <tr class="text-left">
        <th
          *ngFor="let header of headers"
          [class.sort]="header.sortable"
          (click)="sort(header.key, header.sortable)"
          style="min-width: 150px"
        >
          {{ header.value }}
          <i
            *ngIf="orderBy == header.key"
            [class]="
              order == 'desc' ? 'flaticon2-arrow-down' : 'flaticon2-arrow-up'
            "
          ></i>
        </th>
        <th *ngIf="hasActionButtons" style="min-width: 100px">actions</th>
      </tr>
    </thead>
    <tbody *ngIf="!loading">
      <tr
        *ngFor="let row of data; let i = index"
        [class]="i === selectedRow ? 'active' : ''"
        [class.hover]="hasActionSelectRow"
        (click)="actionSelectRow(i)"
      >
        <td
          *ngFor="let item of row"
          [ngClass]="{
            colorActive: item === '● Active',
            colorInactive: item === '● Inactive',
            colorSuccess: item?.class ? item.class == 'colorSuccess' : false,
            colorWarning: item?.class ? item.class == 'colorWarning' : false,
            colorError: item?.class ? item.class == 'colorError' : false
          }"
        >
          <ng-container *ngIf="isArray(item)">
            <span
              *ngFor="let elem of item"
              class="badge room-group"
              (click)="actionRoomGroup(elem.id)"
            >
              {{ elem.code }}
            </span>
          </ng-container>
          <ng-container *ngIf="!isArray(item) && item?.type == 'img'">
            <img
              [src]="item.urlThumbnail"
              alt="Preview"
              class="thumbnail"
              loading="lazy"
              data-toggle="modal"
              data-target="#exampleModalPreview"
              (click)="actionPreview(i)"
            />
          </ng-container>
          <ng-container *ngIf="!isArray(item) && !(item?.type == 'img')">
            {{ item?.value ? item.value : item }}
          </ng-container>
        </td>
        <td *ngIf="hasActionButtons" class="btn-actions">
          <span>
            <a
              (click)="actionButton(i, 'edit')"
              class="btn btn-sm btn-clean btn-icon mr-2"
              title="Edit details"
            >
              <span class="svg-icon svg-icon-md svg-edit">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1" fill="none">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                      transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                    ></path>
                    <rect
                      class="rect-svg-edit"
                      fill="#000000"
                      opacity="0.3"
                      x="5"
                      y="20"
                      width="15"
                      height="2"
                      rx="1"
                    ></rect>
                  </g>
                </svg>
              </span>
            </a>
            <a
              href="javascript:;"
              class="btn btn-sm btn-clean btn-icon"
              (click)="actionButton(i, 'delete')"
              title="Delete"
            >
              <span class="svg-icon svg-icon-md">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" stroke-width="1" fill="none">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                      fill="#000000"
                      fill-rule="nonzero"
                    ></path>
                    <path
                      d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                      fill="#000000"
                      opacity="0.3"
                    ></path>
                  </g>
                </svg>
              </span>
            </a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="loading-table" *ngIf="loading">
    <span class="mr-5">Please Wait!</span>
    <div class="spinner spinner-dark mr-15"></div>
  </div>

  <div class="empty-table" *ngIf="!loading && data?.length == 0">
    <span class="mr-2">Couldn't find any User Type!</span>
    <div class="fa fas fa-search"></div>
  </div>
</div>
